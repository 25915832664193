body {
    font-size: 17px;
    letter-spacing: 0px;
    font-weight: 400;
}
h2 {
    margin-top: 0px;
    display: block;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    unicode-bidi: isolate;
    font-family: 'Playfair Display', 'sans-serif';
    font-size: 2.3529411764706em;
    letter-spacing: 0px;
    font-weight: 700;
    font-style: normal;
    color: white;
}
.logo-wrapper.image {
    margin-bottom: 2em;
}
.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
.App {
    text-align: center;
    /*height: 100%;*/
    width: 100%;
    /*background: lightgrey;*/
    position: absolute;
    align-content: center;
    /*display: flex;*/
}

.section-body {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-grow: 10;
    width: 70%;
    max-width: 1170px;
    min-height: calc(100vh - 7em);
    margin: 0 auto;
}

.section {
    flex-grow: 1;
    padding: 2em;
    text-align: center;
    position: relative;
}

.main {
    position: relative;
    max-width: 1000px;
    top: -10%;
    overflow: scroll;
    left: 20%;
}
.main::-webkit-scrollbar {
    display: none;
}

.svg {
    position: relative;
    left: 34%;
    width: 50%;
}
/*@font-face {*/
/*    font-family: 'Proxima Nova';*/
/*    src: url('./fonts/ProximaNova-Regular.otf') format('otf');*/
/*    !*font-weight: 900; !* Assuming Black is the heaviest weight *!*!*/
/*    !*font-style: normal;*!*/
/*}*/

.text {
    font-family: "Proxima Nova Bl", "Baskerville Old Face";
    font-size: 30px;
    position: relative;
    left: 5%;
}
@media screen and (max-width: 1300px) {
    .main {
        position: relative;
        max-width: 1000px;
        top: -5%;
        overflow: scroll;
        left: 10%;
    }
}

@media screen and (max-width: 1050px) {
    .main {
        position: relative;
        max-width: 1000px;
        top: -5%;
        overflow: scroll;
        left: 2%;
    }
    .svg {
        position: relative;
        left: 25%;
        width: 50%;
    }
    .text {
        font-family: "Proxima Nova";
        font-size: 40px;
    }
}

@media screen and (max-width: 1000px) {
    .main {
        position: relative;
        max-width: 1000px;
        top: -2%;
        left: 0%;
    }
    .svg {
        position: relative;
        left: 17%;
        width: 50%;
    }
}

@media screen and (max-width: 540px) {
    .main {
        position: relative;
        max-width: 1000px;
        top: -2%;
        left: 0%;
    }
    .svg {
         position: relative;
         left: 10%;
         width: 40%;
     }
}

@media screen and (max-width: 500px) and (max-height: 1000px) {

    .svg {
        position: relative;
        left: 4%;
        width: 40%;
    }

    .main {
        position: relative;
        max-width: 1000px;
        top: -2%;
        left: 0%;
    }
}

@media screen and (max-width: 390px) {
    .main {
        position: relative;
        max-width: 1000px;
        top: -2%;
        left: 0%;
    }

    .svg {
        position: relative;
        left: -3%;
        width: 40%;
    }

    .text {
        font-family: "Proxima Nova";
        font-size: 20px;
        width: 315px;
        left: 9%;
        position: relative;
    }
}

@media screen and (max-width: 370px) {
    .main {
        position: relative;
        max-width: 1000px;
        top: -2%;
        left: -2%;
    }
}


.graphic-logo {
    width: auto;
    max-width: 500px;
    max-height: 300px;
    margin: 0 auto;
}

@media only screen and (max-width: 560px) {
    .graphic-logo {
        max-height: 200px;
        padding-bottom: 0;
        max-width: 100%;
    }
}
@media only screen and (max-width: 1024px) {
    .section-body h1:not(.text-logo), .section-body h2, .section-body h3, .section-body h4, .section-body h5, .section-body h6, .text-logo-wrapper {
        font-size: 1.8em !important;
    }
}


